<template>
  <div>
    <!-- MOBILE -->
    <v-navigation-drawer v-model="drawer" app temporary>
      
      <v-subheader class="text-uppercase font-weight-bold my-2">
        <router-link v-if="logoPath.length" to="/">
          <img :src="logoPath" alt="logo" max-height="70">
        </router-link>
        <router-link v-else to="/">
          <h2>{{ resellerCustomName }}</h2>
        </router-link>
      </v-subheader>
      <v-list dense nav>
        <template v-for="(item, index) in modifiedMenu">
          <v-list-item v-if="item.childPages.length < 1" :key="index" :to="item.link">
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-else
            :key="index"
            ref="group"
          >
            <template v-slot:activator>
              <v-list-item-title>
                <div class="group-title" @click="groupTitleClicked(item)" >
                  {{ item.title }}
                </div>
              </v-list-item-title>
            </template>
            <v-list-item
              v-for="(childItem, childIndex) in item.childPages"
              :key="childIndex"
              :to="childItem.link"
            >
              <v-list-item-title class="ml-2">{{ childItem.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-btn
      class="d-md-none drawer-button"
      rounded
      @click="drawer = !drawer"
    >
      <v-icon right>mdi-menu</v-icon>
    </v-btn>

    <!-- DESKTOP (TOP NAV) -->
    <v-app-bar app height="80">
      <v-container class="py-0 px-0 fill-height" :fluid="isFluid">
        <router-link v-if="logoPath.length" to="/" class="d-flex align-left text-decoration-none">
          <img :src="logoPath" alt="logo" max-height="70">
        </router-link>
        <router-link v-else to="/" class="d-flex align-left text-decoration-none">
          <h2>{{ resellerCustomName }}</h2>
        </router-link>
        <v-spacer></v-spacer>
        <div class="d-none d-md-block">
          <template
            v-for="(item, index) in modifiedMenu"   
          >
            <v-btn
              v-if="item.childPages.length < 1"
              :key="index"
              text
              :to="item.link"
              class="body-1"
            >
              {{ item.title }}
            </v-btn>
            <v-menu
              v-else
              :key="index"
              open-on-hover
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  text
                  v-bind="attrs"
                  class="body-1"
                  :to="item.link"
                  v-on="on"
                >
                  {{ item.title }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(childItem, childIndex) in item.childPages"
                  :key="childIndex"
                  :to="childItem.link"
                >
                  <v-list-item-title class="body-1">{{ childItem.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  props: {
    isFluid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      drawer: null
    }
  },
  computed: {
    ...mapGetters({
      menu: 'microsite/menu'
    }),
    modifiedMenu() {
      // "hardcoding" Manage Coverage...
      return [
        ...this.menu,
        ...[{ Id: -1, link: '/manage-coverage', title: 'Manage Coverage', childPages: [] }]
      ]
    },
    ...mapState({
      meta: (state) => state.microsite.meta
    }),
    logoPath() {
      if (this.meta.custom_logo.length)
        return `/${process.env.VUE_APP_DEFAULT_MICROSITE_PUBLIC_FOLDER}${this.meta.custom_logo}`
      else
        return ''
    },
    resellerCustomName() {
        return this.meta.custom_name
    }
  },
  watch: {
    drawer() {
      // manually force any group dropdowns to close when drawer is hidden
      // b/c value prop appears to be bugged
      if (!this.drawer) {
        this.$refs.group.forEach((group) => {
          
          group.isActive = false
        })
      }
    }
  },
  methods: {
    groupTitleClicked(linkObj) {
      if (this.$route.path !== linkObj.link) {
        this.$router.push(linkObj.link)
      }
    }
  }
}
</script>

<style scoped>
.drawer-button {
  position: fixed;
  top: 60px;
  left: -22px;
  z-index: 6;
}
.group-title {
  width: 100%;
  height: 32px;
  padding: 8px 0
}
</style>
