<template>
  <v-layout>
    <v-main>
      <Toolbar />
      <router-view :key="$route.fullPath"></router-view>
      <Footer />
    </v-main>
  </v-layout>
</template>
<script>
import Toolbar from '@/components/default/layout/Toolbar.vue'
import Footer from '@/components/default/layout/Footer.vue'

export default {
  components: {
    Toolbar,
    Footer
  }
}
</script>
